import React from 'react';
import {CircularProgress} from '@material-ui/core';

class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '', error: false, fetching: false, paramsListUTM: null, paramsListGen: null};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let parameters;
        let valueUTM = '';
        let paramsListUTM = {};
        let paramsListGen = {}

        if(typeof window !== 'undefined') {
           
            parameters = window.location.search;
            if(parameters) {
                parameters = parameters.split('&')
                for (let i in parameters) {
                    if(parameters[i].includes('utm_sid')) {
                        valueUTM = parameters[i].split('=')[1];
                    }else if (parameters[i].includes('utm')) {
                        paramsListUTM[parameters[i].split('=')[0].replace('?', '')] = parameters[i].split('=')[1]
                    }else {
                        paramsListGen[parameters[i].split('=')[0].replace('?', '')] = parameters[i].split('=')[1]
                    }
                }
                paramsListUTM = this.sortParams(paramsListUTM);
                this.setState({paramsListUTM: paramsListUTM})
                this.setState({paramsListGen: paramsListGen})
            }
            
            if(!valueUTM) {
                valueUTM = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
            }
            paramsListUTM['utm_sid'] = valueUTM;
            this.setState({paramsListUTM: paramsListUTM})
            const locale = this.getNavigatorLanguage();
            let raw = {event: "f_ad_landing_page_open", brand: "BX", locale};
            let utm_params = {}
            
            for(let i in paramsListUTM) {
                raw[i] = paramsListUTM[i]
                utm_params[i] = paramsListUTM[i]
            }
            if (parameters){
                for(let i in paramsListGen) {
                    raw[i] = paramsListGen[i]
                }
            }
            
            raw.utm_params = utm_params;

            try {
                await fetch(`${window.location.origin}/page-loaded`, {
                    method: 'POST',
                    headers : { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                       },
                    "body": JSON.stringify({raw})
                });
            }catch(error) {
                console.log(error)
            }
        }
    }

    sortParams(list){
        let sortedParams = Object.keys(list)
        .sort()
        .reduce(function (acc, key) { 
            acc[key] = list[key];
            return acc;
        }, {});

        return sortedParams
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
  
    getNavigatorLanguage() {
        if (navigator.languages && navigator.languages.length) {
          return navigator.languages[0];
        } else {
          return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'na';
        }
    }

    async handleSubmit(event) {
      const locale = this.getNavigatorLanguage();
      event.preventDefault();
        
      if (!this.validateEmail(this.state.value)) {
        this.setState({ error: true });
      }else {

        this.setState({ error: false });
        let raw = {event: "f_ad_landing_page_submit_address", email: this.state.value, brand: "BX", locale }
        let utm_params = {}
        let url = '';

        for(let i in this.state.paramsListUTM) {
            raw[i] = this.state.paramsListUTM[i];
            utm_params[i] = this.state.paramsListUTM[i];
            url += '&' + i + '=' + this.state.paramsListUTM[i]
        }
        if (this.state.paramsListGen) {
            for(let i in this.state.paramsListGen) {
                raw[i] = this.state.paramsListGen[i]
                url += '&' + i + '=' + this.state.paramsListGen[i]
            }
        }
        
        raw.utm_params = utm_params;

        console.log(raw)

        this.setState({fetching: true})
        var options = { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ email: this.state.value })
        };

        let respone;
        let result;

        try {
            respone = await fetch(`${window.location.origin}/get-account-type`, options);
            result = await respone.json()
            if(result.type === 'gmail' || result.type === 'outlook' || result.type === 'office365') {
                this.setState({fetching: false})
                raw.account_type = await result.type.toString();
                try {
                    await fetch(`${window.location.origin}/redirect-web`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                           },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
                window.location.replace(`https://web.blix.net?email=${this.state.value}&account_type=${result.type}${url}`)
            }else {
                this.setState({fetching: false})
                raw.account_type = 'other';
                try {
                    await fetch(`${window.location.origin}/redirect-download`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                           },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
                raw.event = "f_ad_landing_page_download_redirect"
                try {
                    await fetch(`${window.location.origin}/redirect-download`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                           },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
                window.location.replace(`/download?email=${this.state.value}&account_type=other${url}`)
                
            }
        }catch(error) {
            console.log(error)
            this.setState({fetching: false})
            raw.account_type = 'other'
                try {
                    await fetch(`${window.location.origin}/redirect-download`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
            raw.event = "f_ad_landing_page_download_redirect";
                try {
                    await fetch(`${window.location.origin}/redirect-download`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                           },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
            window.location.replace(`/download?email=${this.state.value}&account_type=other${url}`)
        }
      }
    }

    render() {
      return (
          <div>
              <div className="landing-page">
                <form onSubmit={this.handleSubmit}>
                    <div className="landing-page-title">Boost your Team productivity today with Blix </div>
                    <div className="landing-button-wrap">
                        <div>
                        <input type="text" value={this.state.value} onChange={this.handleChange} placeholder="Enter Work Email" className="landing-input" />
                            {this.state.error && <div style={{fontSize: '12px', color: 'red'}}>Please insert a correct email address</div>}
                        </div>
                            <div style={{textAlign: 'center'}}>
                                <button type="submit" className="sub-btn">Start My 14-Day Free Trial</button>
                                <div style={{color: 'rgba(255, 255, 255, 0.63)', padding: '10px', fontSize: '13px'}}>No credit card required</div>
                            </div>
                    </div>
                    <div>
                        {this.state.fetching && <CircularProgress color="primary" style={{marginTop: '10px'}} />}
                    </div>
                </form>
            </div>
            <video autoPlay muted loop playsInline id="bg-video">
                <source src="/images/landing/BGvideo.mp4" type="video/mp4"/>
            </video>
          </div>
          
      );
    }
  }

export default NameForm
